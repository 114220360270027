import './Content.scss'
import Section from './section/Section';
import { SectionData } from './section';


export default (props: {scrolledPage: boolean}) => {
    const sections: SectionData[] = [
        {
            key: '1',
            title: 'Whoami',
            content: '<span>With a decade of experience in the tech industry, I possess a wide range of skills in programming languages and frameworks, including <b>Java</b>, <b>Scala</b>, <b>GO</b>, <b>Angular</b>, <b>React</b>.</span><br/><span> My track record of delivering high-quality and efficient code is evident in my experience of developing web applications and building robust systems.</span> <br/><span> My keen eye for detail and passion for staying current in the industry make me a valuable asset to any team.</span> <br/><span> Currently, I am seeking a part-time freelance job for 2-3 hours per day, a perfect opportunity for a company to engage my skills on a project basis.</span> <br/><span> If you are interested drop me an <b>email</b> or contact me through <b>Linkedin</b>.</span>',
            tags: [],
            scrolledPage: props.scrolledPage
        },
        {
            key: '2',
            title: 'Services',
            tags: ['Programming', 'Consulting', 'Mentoring'],
            content: '',
            scrolledPage: props.scrolledPage
        }
    ];

  
    return (
        <div className='page-content parallax-background'>
            {
                sections.map((section, i) => (
                    <div key={section.key}>
                         <Section {...section}/>
                         <div className='space'></div>
                    </div>
                   
                ))
            }
        </div>
    )
}