import { SectionData } from '../section';
import './Section.scss';

export default (props: SectionData) => {
    const tags = props.tags;
    const hasTags = tags && tags.length > 0;
    return (
           <div className='section'>
            <div className='title'>{props.title}</div>
            {props.scrolledPage && props.content.length > 0 && <div className='content-wrapper'>
                <div className='content fade-in-text' dangerouslySetInnerHTML={{ __html: props.content }}></div>
            </div>
            }   
            {props.scrolledPage && hasTags &&
                <div className='tags'>
                        {tags.map((t,i) => <div key={i+100} className='tag'>{t}</div>)}
                </div>
            }
        </div>
        
    );
}