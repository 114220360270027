import './Footer.scss'

const encodedKey = 'YW50aWJvdGRlZmVuY2VrZXkK';
const CryptoJS = require('crypto-js');

export default () => {
    const currentYear = new Date().getFullYear();
    const icons = [
        { src: 'github-icon.png', href: 'U2FsdGVkX19inAHmxjPnTbUSVkh7QRYJnYhT+9r2llhqTX6Mk7ENpHIFs6+DxlPt' },
        { src: 'ln-icon.png', href: 'U2FsdGVkX182Y+i7iTYvjEm3q3ihIVA5ZVguZjRT9yiUxSCdkbVmrAOateTVdIPreRKCzHgBAUn0xPAYecOpzw==' },
        { src: 'email-icon.png', href: 'U2FsdGVkX19xOvdpRDIQJIU3jAmTPSTqNjkF2Qss7pYHi2VthjzkwwtYF3Znd+Z5FjxVVuMKoEF49Xr8WfsBNQ==' }
    ];
    const openLink = (encryptedUrl: string) => {
        const key = atob(encodedKey);
        const bytes = CryptoJS.AES.decrypt(encryptedUrl, key);
        const url =  bytes.toString(CryptoJS.enc.Utf8);
        window.open(url, '_blank');
    };
    return (
        <div className='footer'>
            <div className='icons-bar'>
                {
                    icons.map(icon => (
                        <div key={icon.src} className='contact-icon' onClick={_ => openLink(icon.href)}>
                            <img src={icon.src} />
                        </div>
                    ))
                }
            </div>
            <div className='text'>
                Copyright © {currentYear} <strong>Dev-Set</strong> All Rights Reserved.
            </div>
        </div>
    )
}