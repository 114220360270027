import './Scrollme.scss'


export default () => {
    const handleScrollClick = (e: any) => window.scrollTo(0, 20);
    return (
        <div className='scrollme'>
            <div className='scrollme-inner'>
                <div onClick={handleScrollClick} className='scroller'></div>
            </div>
        </div>
    )
};