import './App.scss';
import Content from './components/content/Content';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import { useEffect, useState } from 'react'
import Scrollme from './components/scrollme/Scrollme';


export default () => {
  const [scrolledPage, setScrolledPage] = useState(false);
  useEffect(() => {
      const onScroll = (e: any) => {
          const scrollTop = e?.target?.scrollingElement?.scrollTop;
          setScrolledPage(scrollTop && scrollTop > 10);
      }
      window.addEventListener("scroll", onScroll);
      return () => window.removeEventListener("scroll", onScroll);
  }, [scrolledPage]);
  return (
    <div className='main parallax-background'>
      <Header scrolledPage={scrolledPage}/>
      <Content scrolledPage={scrolledPage}/>
      {!scrolledPage && <Scrollme/>}
      <Footer/>
    </div>
  );
}

